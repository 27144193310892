import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/homestyle.css';
import '../../css/loginstyle.css';
import Footer from './footer';
import { axiosconn } from '../util/usercontext';
import Navbar from './Navbar';

function AddProduct() {
    const navigate = useNavigate();
    const uid = localStorage.getItem('uid');
    const [addProductForm, setFormData] = useState({
        uid: uid,
        name: '',
        category: '',
        description: '',
        price: '',
        stock: 0,
        image: ''
    });

    const onChange = (e) => {
        setFormData({
            ...addProductForm,
            [e.target.name]: e.target.value
        })
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const addImg = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setFormData({ ...addProductForm, image: base64 });
    };

    const addProduct = async (e) => {
        e.preventDefault();
        try {
            const ipdata = JSON.stringify(addProductForm);
            const loginToken = localStorage.getItem('token');
            if (loginToken) {
                const { data } = await axiosconn.post('addProduct', ipdata, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + loginToken
                    }
                });
                if (data.success) {
                    e.target.reset();
                    alert('You have successfully added the product!');
                    navigate("/products");
                }
                else if (!data.success && data.message) {
                    alert(data.message);
                }
            }
        }
        catch (err) {
            alert('Server Error!');
        }

    }

    return (
        <div>
            <Navbar />
            <div className="content">
                <form className="add-product" onSubmit={addProduct}>
                    <div className="form-row">
                        <div className="form-labels">
                            Name:
                        </div>
                        <div className="form-inputs">
                            <input type="text" id="pname" name="name" placeholder="Name of Product" onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Category:
                        </div>
                        <div className="form-inputs">
                            <select id="category" name="category" onChange={onChange}>
                                <option defaultValue="0">Select Category</option>
                                <option defaultValue="food">Food</option>
                                <option defaultValue="clothes">Clothes</option>
                                <option defaultValue="furniture">Furtniture</option>
                                <option defaultValue="stationary">Stationary</option>
                                <option defaultValue="Electronics">Electronics</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Description:
                        </div>
                        <div className="form-inputs">
                            <textarea name="description" placeholder="Description of Product" onChange={onChange} ></textarea>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Price:
                        </div>
                        <div className="form-inputs">
                            <input type="number" id="price" name="price" min="0" defaultValue="0" step="0.01" placeholder="Price" onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Product Image:
                        </div>
                        <div className="form-inputs">
                            <input
                                type="file"
                                label="Image"
                                name="image"
                                accept=".jpeg, .png, .jpg"
                                required
                                onChange={(e) => addImg(e)}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Quantity:
                        </div>
                        <div className="form-inputs">
                            <input type="number" id="price" name="stock" defaultValue="0" placeholder="Quantity" onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <button type="submit" className='submit'>Add Product</button>
                    </div>
                </form>
            </div>
            <Footer style={{ position: 'absolute' }} />
        </div >
    )
}
export default AddProduct;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/homestyle.css';
import '../../css/pagestyle.css';
import '../../css/chartstyle.css';
import Footer from './footer';
import { axiosconn } from '../util/usercontext';
import Navbar from './Navbar';

function ManageStudent() {

    const [stuData, setStuData] = useState([]);
    useEffect(() => {
        getStuData()
    }, []);
    const getStuData = async () => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const { data } = await axiosconn.get('getStuUsers', {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            });
            if (data.success && data.data) {
                setStuData(data.data);
            }
        }
    }
    const deleteRow = async (id) => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const ipdata = JSON.stringify({ uid: id });
            const { data } = await axiosconn.post('delUser', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            });
            if (data.success) {
                alert("Data Deleted Successfully");
                getStuData();
            } else {
                alert(data.message);
                getStuData();
            }
        }
    }

    return (
        <div>
            <Navbar />
            <div className="row content">
                <table>
                    <caption><h1>Manage Student</h1></caption>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Major</th>
                            <th>School</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stuData.map((item, index) => (
                            <tr className="trb">
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone_number}</td>
                                <td>{item.city}</td>
                                <td>{item.state}</td>
                                <td>{item.major}</td>
                                <td>{item.school}</td>
                                <td><Link to="/editProfile" className='submit' style={{padding: "5px 27px"}} state={{ uid: item.uid }} align="center">EDIT</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Footer />
        </div >
    )
}
export default ManageStudent;
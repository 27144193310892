import React, { useContext } from 'react';
import "../../css/homestyle.css";
import "../../css/pagestyle.css";
import { userCon } from '../util/usercontext';


export default function AdminHeader() {
    const { logout } = useContext(userCon);
    const role = localStorage.getItem('role');
    const onlogout = (e) => {
        logout();
    };
    return (
        <div className="dropdownContent">
            <a href={role == 'school_admin' ? "/school" : "/admin"}>DashBoard</a>
            <a href="/myprofile">Profile</a>
            <a href="/managebusiness">Manage Business</a>
            <a href="/managestudent">Manage Student</a>
            {role == 'super_admin' && <a href="/manageschool">Manage Admin</a>}
            <a href="/products">Products</a>
            <a href="/Ad">Advertisements</a>
            <a href="/register">New User</a>
            <a href="/addproduct">Add Product</a>
            <a href="/addad">Add Ad</a>
            <a href="/createClub">Add Club</a>
            <a href="/login" onClick={onlogout}>Logout</a>
        </div>
    )
}



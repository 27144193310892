import React, { useState } from 'react';
import '../../css/homestyle.css';
import '../../css/loginstyle.css';
import Footer from './footer';
import { axiosconn } from '../util/usercontext';
import Navbar from './Navbar';

function CreateClub() {
    const uid = localStorage.getItem('uid');
    const [clubForm, setFormData] = useState({
        uid: uid,
        name: '',
        description: '',
    });

    const onChange = (e) => {
        setFormData({
            ...clubForm,
            [e.target.name]: e.target.value
        })
    }

    const createclub = async (e) => {
        e.preventDefault();
        console.log(clubForm);
        try {
            const loginToken = localStorage.getItem('token');
            if (loginToken) {
                const ipdata = JSON.stringify(clubForm);
                const loginToken = localStorage.getItem('token');
                if (loginToken) {
                    const { data } = await axiosconn.post('createClub', ipdata, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + loginToken,
                        }
                    });
                    if (data.success) {
                        e.target.reset();
                        alert('You have successfully created club.');
                    }
                    else if (!data.success && data.message) {
                        alert(data.message);
                    }
                }
            }
        }
        catch (err) {
            alert('Server Error!');
        }

    }

    return (
        <div>
            <Navbar />
            <div className="content row">
                <form className="add-product" onSubmit={createclub}>
                    <div className="form-row">
                        <div className="form-labels">
                            Club Name:
                        </div>
                        <div className="form-inputs">
                            <input type="name" name="name" placeholder="Name of the Club" onChange={onChange} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Description:
                        </div>
                        <div className="form-inputs">
                            <textarea name="description" placeholder="Enter A Suitable Description..." onChange={onChange}></textarea>
                        </div>
                    </div>
                    <div className="form-row">
                        <button className='submit'>Create Club</button>
                    </div>
                </form>
            </div>
            <Footer style={{ position: 'absolute' }} />
        </div >
    )
}
export default CreateClub;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/homestyle.css';
import '../../css/loginstyle.css';
import Footer from './footer';
import { axiosconn } from '../util/usercontext';
import Navbar from './Navbar';


function AddAd() {
    const navigate = useNavigate();
    const uid = localStorage.getItem('uid');

    const [adForm, setFormData] = useState({
        uid: uid,
        name: '',
        content: '',
        image: ''
    });

    const onChange = (e) => {
        setFormData({
            ...adForm,
            [e.target.name]: e.target.value
        })
    }

    const createadd = async (e) => {
        e.preventDefault();
        console.log(adForm);
        try {
            const ipdata = JSON.stringify(adForm);
            const loginToken = localStorage.getItem('token');
            if (loginToken) {
                const { data } = await axiosconn.post('createadd', ipdata, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + loginToken
                    }
                });
                if (data.success) {
                    e.target.reset();
                    alert('You have successfully created Ad!');
                    navigate("/Ad");
                }
                else if (!data.success && data.message) {
                    alert(data.message);
                }
            }
        }
        catch (err) {
            alert('Server Error!');
        }

    }
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const addImg = async (e) => {
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setFormData({ ...adForm, image: base64 });
    };

    return (
        <div>
            <Navbar />
            <div className="content row">
                <form className="add-product" onSubmit={createadd}>
                    <div className="form-row">
                        <div className="form-labels">
                            Advertisement Name:
                        </div>
                        <div className="form-inputs">
                            <input name="name" placeholder="Name of Ad" onChange={onChange} required></input>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Description:
                        </div>
                        <div className="form-inputs">
                            <textarea name="content" placeholder="Description of Ad" onChange={onChange} required></textarea>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-labels">
                            Advertisement Image:
                        </div>
                        <div className="form-inputs">
                            <input
                                type="file"
                                label="Image"
                                name="image"
                                accept=".jpeg, .png, .jpg"
                                required
                                onChange={(e) => addImg(e)}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <button className='submit'>Upload Advertisement</button>
                    </div>
                </form>
            </div>
            <Footer style={{ position: 'absolute' }} />
        </div >
    )
}
export default AddAd;
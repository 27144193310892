import React, { useEffect, useState } from 'react';
import { axiosconn } from "../util/usercontext";
import { Link } from 'react-router-dom';
import '../../css/homestyle.css';
import '../../css/pagestyle.css';
import Footer from './footer';
import Navbar from './Navbar';

function BusinessDash() {
    const userId = localStorage.getItem('uid');
    const fname = localStorage.getItem('fname');
    const username = localStorage.getItem('username');
    const [uID, setuID] = useState({
        uid: userId
    });

    const getDetails = async () => {
        const loginToken = localStorage.getItem('token');
        const uid = JSON.stringify(uID);
        if (loginToken) {
            await axiosconn.post('getProdById', uid, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            }).then(function (response) {
                const res = response.data;
                if (res.success && res.data) {
                    setProductData(res.data);
                    console.log(productData);
                }
            }).catch(function (error) {
                console.log(error);
            });
            await axiosconn.post('getAdById', uid, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            }).then(function (response) {
                const res = response.data;
                if (res.success && res.data) {
                    setAdData(res.data);
                    console.log(adData);
                }
            }).catch(function (error) {
                console.log(error);
            });

        }
    }

    const [productData, setProductData] = useState([]);
    const [adData, setAdData] = useState([]);
    useEffect(() => {
        getDetails();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="content row">
                <div className="content-header">
                    <h2>Welcome, {fname}!</h2>
                </div>
                <div className="row">
                    <div className="left-column">
                        <div className="card">
                            <h1>Your Products</h1>
                            <div className="product-row">
                                {productData ? (
                                    productData.map((item, index) => (
                                        <div className="product-column">
                                            <div className="product">
                                                <Link to="/Product" state={{ pid: item.pid }}>
                                                    <img src={item.image} alt="" style={{ width: "100%" }} />
                                                    <h3>{item.name}</h3></Link>
                                                <p className="price">{item.price}</p>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))) : 'Nothing to Show'}
                            </div>
                            <div className="add">
                                <Link to="/addProduct"><i className="gg-add"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="right-column">
                        <div className="card">
                            <h1>Ads</h1>
                            <div className="posts">
                                {adData ? (
                                    adData.map((item, index) => (
                                        <div className="ad-container">
                                            <a href="/ad">
                                                <img src={item.image} alt="" style={{ width: "100%" }} />
                                                <div className="ad-overlay"> {item.content}</div></a>
                                        </div>
                                    ))) : 'Nothing to Show'}
                                <div className="add">
                                    <Link to="/addAd"><i className="gg-add"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href={"chat.php?uname="+ username +"&isLI=b5bea41b6c623f7c09f1bf24dcae58ebab3c0cdd90ad966bc43a45b44867e12b"} class="float" target="_blank">
            <i class="gg-comment myFloat"></i> </a>
            <Footer />
        </div>
    )
}
export default BusinessDash;
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Footer from "./footer";
import { axiosconn } from "../util/usercontext";
import Navbar from './Navbar';

function Product() {
    const location = useLocation();
    const pid = location.state.pid;
    const userId = localStorage.getItem('uid');
    const [productData, setProuctData] = useState([]);
    useEffect(() => {
        getProduct()
    }, []);
    const getProduct = async () => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const ipdata = JSON.stringify({ pid: pid })
            const { data } = await axiosconn.post('getProduct', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            });
            if (data.success && data.data) {
                setProuctData(data.data);
            }
        }
    }
    const [formData, setformData] = useState({quantity: 0});
    const onChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }
    const addToCart = async (pid) => {
        const loginToken = localStorage.getItem('token');
        try {
            if (loginToken) {
                const ipdata = JSON.stringify({ pid: pid, uid: userId, quantity: formData.quantity })
                const { data } = await axiosconn.post('addToCart', ipdata, {
                    headers: {
                        'Authorization': 'Bearer ' + loginToken,
                        'Content-Type': 'application/json',
                    }
                });
                if (data.success) {
                    // e.target.reset();
                    alert("Added to Cart");
                } else if (!data.success && data.message) {
                    alert(data.message);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div>
            <Navbar />
            <div className="content" style={{ height: '115vh' }}>
                <div className="product-info">
                    <h2>{productData.name}</h2>
                    <img src={productData.image} alt="" style={{ width: '100%' }} />
                    <p className="price">{productData.price}</p>
                    <p>{productData.description}</p>
                    <div style={{ justifyContent: 'center' }}>
                        <div style={{ paddingRight: 80 }} >
                            <p><input type="number" placeholder="Quantity" onChange={onChange} name="quantity"/></p>
                        </div>
                        <p><button onClick={() => addToCart(productData.pid)}>Add to Cart</button></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Product
import React, { useContext } from 'react';
import { Link } from "react-router-dom"
import "../../css/homestyle.css";
import "../../css/pagestyle.css";
import user from '../util/user';
import { userCon } from '../util/usercontext';
import AdminHeader from './AdminHeader';

export default function Navbar() {
    const role = localStorage.getItem('role');
    const { logout } = useContext(userCon);
    const onlogout = (e) => {
        logout();
    };
    return (
        <nav className="header">
            <Link to="/" className="site-title" style={{ fontWeight: 'bold', color: 'white', fontSize: 30 }}>Mav Market</Link>
            {user.isLoggedIn() && <div className="dropdown" >
                <i className="ggprofile"></i>
                {role == 'student' && <div className="dropdownContent">
                    <a href="/student">DashBoard</a>
                    <a href="/profile">Profile</a>
                    <a href="/cart">Cart</a>
                    <a href="/order">Orders</a>
                    <a href="/products">Products</a>
                    <a href="/addproduct">Add Product</a>
                    <a href="/login" onClick={onlogout}>Logout</a>
                </div>}
                {role == 'business_owner' && <div className="dropdownContent">
                    <a href="/business">DashBoard</a>
                    <a href="/businessprofile">Profile</a>
                    <a href="/products">Products</a>
                    <a href="/addproduct">Add Product</a>
                    <a href="/addad">Add Ad</a>
                    <a href="/login" onClick={onlogout}>Logout</a>
                </div>}
                {(role == 'super_admin' || role == 'school_admin') && <AdminHeader />}
            </div>}
            <div className="header-right">
                <a href="/home" style={{ color: 'white' }} >Home</a>
                <a href="/about" style={{ color: 'white' }} >About</a>
                <a href="/service" style={{ color: 'white' }} >Service</a>
                <a href="http://dmp4205.uta.cloud/mavmarket/blog/" style={{ color: 'white' }} >Blog</a>
                <a href="/contact" style={{ color: 'white' }} >Contact</a>
                <a href="/login" style={{ color: 'white' }} >Login/Register</a>
            </div>
        </nav>

    )
}



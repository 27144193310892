import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/chartstyle.css';
import '../../css/homestyle.css';
import '../../css/pagestyle.css';
import Footer from './footer';
import { axiosconn } from '../util/usercontext';
import Navbar from './Navbar';

function ManageSchool() {
    const [schData, setSchData] = useState([]);
    useEffect(() => {
        getSchData()
    }, []);
    const getSchData = async () => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const { data } = await axiosconn.get('getSchUsers', {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            });
            if (data.success && data.data) {
                setSchData(data.data);
            }
        }
    }

    return (
        <div>
            <Navbar />
            <div className="row content">
                <table>
                    <caption><h1>Manage School Admins</h1></caption>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>School</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {schData.map((item, index) => (
                            <tr className="trb">
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone_number}</td>
                                <td>{item.school}</td>
                                <td><Link to="/editSchool" className='submit' style={{padding: "5px 27px"}} state={{ uid: item.uid }} align="center">EDIT</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Footer />
        </div >
    )
}
export default ManageSchool;
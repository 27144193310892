import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Footer from './footer';
import '../../css/homestyle.css';
import '../../css/loginstyle.css';
import { axiosconn } from "../util/usercontext";
import Navbar from './Navbar';

function Club() {
    const location = useLocation();
    const uid = localStorage.getItem('uid');
    const clubid = location.state.cid;
    const navigate = useNavigate();
    const [clubData, setClubData] = useState({
        clubName: location.state.cname,
        clubs: []
    });
    useEffect(() => {
        async function asyncCall() {
            await getClub();
        }
        asyncCall();
    }, []);
    const getClub = async () => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const ipdata = JSON.stringify({ clubId: clubid, uid: uid })
            console.log(clubid);
            await axiosconn.post('getClub', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            }).then(function (response) {
                const res = response.data;
                if (res.success && res.data) {
                    if (res.data.length > 0) {
                        const cData = {};
                        cData.clubName = res.data[0]["name"];
                        cData.clubs = res.data;
                        setClubData(cData);
                    }
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
    const joinClub = async (e) => {
        const loginToken = localStorage.getItem('token');
        try {
            if (loginToken) {
                const ipdata = JSON.stringify({ clubId: clubid, uid: uid })
                const { data } = await axiosconn.post('joinClub', ipdata, {
                    headers: {
                        'Authorization': 'Bearer ' + loginToken,
                        'Content-Type': 'application/json'
                    }
                });
                if (data.success) {
                    // e.target.reset();
                    alert("Joined Club Successfully");
                    navigate("/student");
                } else {
                    alert("You are already a member of the club!")
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const leaveClub = async (e) => {
        const loginToken = localStorage.getItem('token');
        try {
            if (loginToken) {
                const ipdata = JSON.stringify({ clubId: clubid, uid: uid })
                const { data } = await axiosconn.post('leaveClub', ipdata, {
                    headers: {
                        'Authorization': 'Bearer ' + loginToken,
                        'Content-Type': 'application/json'
                    }
                });
                if (data.success) {
                    // e.target.reset();
                    alert("Club Left!");
                    navigate("/student");
                } else {
                    alert("You are not a member of the club!");
                    // alert(data.message)
                    window.location.href = window.location.href;
                }
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div>
            <Navbar />
            <div className="content row">
                <div className="product-info">
                    <img src="/images/club.png" className="avicon" alt="" style={{ width: '50%' }} />
                    <div>{clubData && <h2>{clubData.clubName}</h2>}</div>
                    <p>Members:</p>
                    <table>
                        {clubData && clubData.clubs.map((item, index) => (
                            <tbody>
                                <tr className="trb">
                                    <td>{index + 1}</td>
                                    <td>{item.fname + ' ' + item.lname}</td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
                <div align="center">
                    <br />
                    <button style={{ marginRight: "5em" }} className='submit' onClick={() => joinClub(clubData.club_id)}>JOIN CLUB</button>
                    <button style={{ marginRight: "0em" }} className='delete' onClick={() => leaveClub(clubData.club_id)}>LEAVE CLUB</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Club;
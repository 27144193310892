import React, { useState, useEffect } from 'react';
import Footer from './footer';
import { axiosconn } from "../util/usercontext";
import Navbar from './Navbar';

function Ad() {
    const role = localStorage.getItem('role');
    const [adData, setAdData] = useState([]);
    useEffect(() => {
        getAd()
    }, []);
    const getAd = async () => {
        const loginToken = localStorage.getItem('token');
        const uid = localStorage.getItem('uid');
        let allData =[];
        if (loginToken) {
            if (role == 'business_owner') {
                const ipdata = JSON.stringify({ uid: uid });
                const { data } = await axiosconn.post('getAddByUser', ipdata, {
                    headers: {
                        'Authorization': 'Bearer ' + loginToken,
                        'Content-Type': 'application/json',
                    }
                }).then(function (response) {
                    const res = response.data;
                    if (res.success && res.data) {
                        allData = res.data;
                        console.log(allData)
                        setAdData(allData)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                const { data } = await axiosconn.get('getAllAds', {
                    headers: {
                        'Authorization': 'Bearer ' + loginToken,
                        'Content-Type': 'application/json',
                    }
                });
                if (data.success && data.data) {
                    setAdData(data.data);
                }
            }
        }
    }

    const deleteAd = async (adId) => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const ipdata = JSON.stringify({ adid: adId });
            const { data } = await axiosconn.post('deleteAd', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                }
            });
            if (data.success) {
                alert(data.message);
                window.location.href = window.location.href;
            } else {
                alert(data.message);
            }
        }
    }

    return (
        <div>
            <Navbar />
            <div className="content row">
                {adData.length > 0 && adData.map((item, index) => (
                    <div className="product-info">
                        <h2>{item.name}</h2>
                        <img src={item.image} alt="" style={{ width: "100%" }} />
                        <p>{item.content}</p>
                        {role != 'student' && <button onClick={() => deleteAd(item.ad_id)} className='submit'>Delete Advertisement</button>}
                        <br />
                    </div>
                ))}
                {adData.length <= 0 && <div align="center"> No Data To Display!!!</div>}
            </div>
            <Footer />
        </div>
    )
}

export default Ad
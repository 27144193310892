import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosconn } from "../util/usercontext";
import Footer from "./footer";
import Navbar from "./Navbar";

function EditSchool() {
    const location = useLocation();
    const userId = location.state.uid;
    const navigate = useNavigate();
    const [updateForm, setFormData] = useState({
        uid: userId,
        fname: '',
        lname: '',
        uname: '',
        email: '',
        phone: '',
        school: '',
    });
    const [uID, setuID] = useState({
        uid: userId,
        role: 'school_admin',
    });
    const [schools, setSchools] = useState([]);

    const getDetails = async () => {
        const loginToken = localStorage.getItem('token');
        const ipdata = JSON.stringify(uID);
        if (loginToken) {
            await axiosconn.post('getAdminProfile', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            }).then(function (response) {
                const res = response.data;
                if (res.success && res.data) {
                    setFormData({
                        ...updateForm,
                        fname: res.data[0].fname,
                        lname: res.data[0].lname,
                        uname: res.data[0].username,
                        email: res.data[0].email,
                        phone: res.data[0].phone_number,
                        school: res.data[0].school_id,
                    });
                }
            }).catch(function (error) {
                console.log(error);
            });
            await axiosconn.get('getAllSchools', {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                }
            }).then(function (response) {
                const res = response.data;
                if (res.success && res.data) {
                    console.log(res.data);
                    setSchools(res.data);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }

    useEffect(() => {
        async function asyncCall() {
            await getDetails();
        }
        asyncCall();
    }, []);

    const onChange = (e) => {
        setFormData({
            ...updateForm,
            [e.target.name]: e.target.value
        });
        console.log(updateForm);
    }

    const editprofile = async (e) => {
        e.preventDefault();
        console.log(updateForm);
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            try {
                const ipdata = JSON.stringify(updateForm);
                const loginToken = localStorage.getItem('token');
                if (loginToken) {
                    const { data } = await axiosconn.post('editSchoolProfile', ipdata, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + loginToken
                        }
                    });
                    if (data.success) {
                        // e.target.reset();
                        alert('Details Updated Successfully');
                        if (role === 'school_admin') {
                            navigate("/school");
                        } else {
                            navigate("/admin");
                        }
                    }
                    else if (!data.success && data.message) {
                        alert(data.message);
                    }
                }
            }
            catch (err) {
                console.log(err);
                alert('Server Error!');
            }
        }
    }

    const deleteprofile = async (e) => {
        e.preventDefault();
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const ipdata = JSON.stringify({ uid: userId });
            const { data } = await axiosconn.post('delUser', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            });
            if (data.success) {
                alert("Data Deleted Successfully");
                // navigate("/login");
            } else {
                alert(data.message);
            }
        }
    }

    return (
        <div>
            <Navbar />
            <div className="content row">
                {updateForm &&
                    <form className="add-product" onSubmit={editprofile}>
                        <div className="form-row">
                            <div className="form-labels">
                                First Name:
                            </div>
                            <div className="form-inputs">
                                <input type="text" id="fname" name="fname" value={updateForm.fname || ''} onChange={onChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-labels">
                                Last Name:
                            </div>
                            <div className="form-inputs">
                                <input type="text" id="lname" name="lname" value={updateForm.lname || ''} onChange={onChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-labels">
                                Username:
                            </div>
                            <div className="form-inputs">
                                <input type="text" id="uname" name="uname" value={updateForm.uname || ''} onChange={onChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-labels">
                                Email:
                            </div>
                            <div className="form-inputs">
                                <input type="text" id="email" name="email" value={updateForm.email || ''} onChange={onChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-labels">
                                Phone Number:
                            </div>
                            <div className="form-inputs">
                                <input type="text" id="phone" name="phone" value={updateForm.phone || ''} onChange={onChange} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-labels">
                                School:
                            </div>
                            <div className="form-inputs">
                                <select name="school" placeholder="School" onChange={onChange} value={updateForm.school || ''}>
                                    <option value="">None</option>
                                    {schools ? (
                                        schools.map((item, index) => (
                                            <option value={item.school_id}>{item.name}</option>
                                        ))) : ''}
                                </select>
                            </div>
                        </div>
                        <div align="center">
                            <button className='submit' style={{ marginRight: "5em" }} onClick={editprofile}>Edit Profile</button>
                            <button className='delete' style={{ marginRight: "10em" }} onClick={deleteprofile}>Delete Profile</button>
                        </div>
                    </form>}
            </div>
            <Footer style={{ position: 'absolute' }} />
        </div>
    )
}

export default EditSchool;
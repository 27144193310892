import React, { useState, useEffect } from 'react';
import Footer from "./footer";
import { axiosconn } from "../util/usercontext";
import NotFound from './NotFound';
import Navbar from './Navbar';

function ListProducts() {
    const userId = localStorage.getItem('uid');
    const role = localStorage.getItem('role');
    const [formData, setFormData] = useState({
        uid: userId
    });
    const [productData, setProuctData] = useState([]);
    useEffect(() => {
        getProduct()
    }, []);

    const deleteProduct = async (prodId) => {
        const loginToken = localStorage.getItem('token');
        if (loginToken) {
            const ipdata = JSON.stringify({ pid: prodId });
            const { data } = await axiosconn.post('deleteProduct', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            });
            if (data.success) {
                alert(data.message);
                window.location.href = window.location.href;
            } else {
                alert(data.message);
            }
        }
    }
    const getProduct = async () => {
        const loginToken = localStorage.getItem('token');
        let allData = [];
        if (loginToken) {
            await axiosconn.get('getAllProducts', {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                }
            }).then(function (response) {
                const res = response.data;
                if (res.success && res.data) {
                    allData = res.data;
                }
            }).catch(function (error) {
                console.log(error);
            });
            const ipdata = JSON.stringify(formData);
            await axiosconn.post('getProdById', ipdata, {
                headers: {
                    'Authorization': 'Bearer ' + loginToken,
                    'Content-Type': 'application/json',
                }
            }).then(function (response) {
                const res = response.data;
                let result = [];
                if (res.success && res.data) {
                    result = res.data.map((item) => item.uid);
                }
                let final = [];
                allData.forEach(item => {
                    let d = {};
                    Object.assign(d, item);
                    d['currUser'] = false;

                    if (result.includes(d.uid) > 0 || role == 'super_admin' || role == 'school_admin') {
                        d['currUser'] = true;
                    }
                    final.push(d);
                });
                setProuctData(final);
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
    return (
        <div>
            <Navbar />
            <div className="content row">
                <table>
                    <caption><h1>Product Details</h1></caption>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Image</th>
                            <th>Quantity</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productData.length < 1 && <NotFound />}
                        {productData.map((item, index) => (
                            <tr className="trb">
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.price}</td>
                                <td><img src={item.image} alt="" height="150" width="150" /></td>
                                <td>{item.stock}</td>
                                <td>{item.description}</td>
                                <td><button onClick={() => deleteProduct(item.pid)} disabled={!item.currUser} className='submit'>Delete Product</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Footer />
        </div>
    )
}

export default ListProducts;